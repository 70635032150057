<template>
    <div :class="data.Class" :style="windowWidth < 600 ? data.MobileStyle : data.Style" v-if="data">
        <b-button type="is-dark" v-for="(button, indexButton) in data.Data" :key="indexButton" class="m-1" outlined>{{ button.Name }}</b-button>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    }
}
</script>
